/* import tailwind */
@tailwind base;
@tailwind components;
@tailwind utilities;

/* import libs */
@import "~nprogress/nprogress.css";
@import "~react-toastify/dist/ReactToastify.css";
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,400;0,500;0,700;1,400&display=swap');

body {
  margin: 0;
  font-family:"Noto Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#nprogress .bar {
  background: #FDA100 !important;
}

#nprogress .peg {
  box-shadow: 0 0 10px #FDA100, 0 0 5px #FDA100;
}

#nprogress .spinner-icon {
  border-top-color: #FDA100;
  border-left-color: #FDA100;
}



/* nprogress */
#nprogress {
  left: 0;
  top: 0;
  z-index: 9999;
  position: fixed;
  height: 100%;
  width: 100%;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.725);
  display: flex;
  align-items: center;
  justify-content: center;
}
#nprogress .bar {
  top: 0;
  left: 0;
  z-index: 9999;
  position: fixed;
  height: 4px;
  width: 100%;
  background: #29d;
}
#nprogress .spinner {
  display: inline-flex;
  position: fixed;
  top: 50%;
  left: 0vw;
  width: 80px;
  height: 80px;
  margin: auto;
}

#nprogress .spinner .spinner-icon {
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 2px solid;
  border-color: #e4e4ed;
  border-right-color: #1700C4;
  animation: lds-dual-ring 0.5s infinite linear;
}


@keyframes lds-dual-ring {
  to {
    transform: rotate(1turn);
  }
}





